export const languageHashValues= {
  "GLOBAL": ".0562725a111ff52f92f0",
  "U4302_TRANSACTIONSDETAIL": ".787fad304bf0be45c7c4",
  "U4400_PAYMENT": ".8494421e12fffcb1ea76",
  "U5122_INVOICEMODIFICATIONAPPROVAL": ".8a4b894e4629ebb3f6a9",
  "U2117_PADAGREEMENTINVITATION": ".1c7e93908d625f47a6fc",
  "U2000_PAD": ".7606dcf921442e1bcad5",
  "U4402_INVOICEPORTALPAYMENT": ".657ec0a1a880275dae55",
  "U2000_BANKACCOUNTCREATION": ".9a1869d4d183630cf1c2",
  "U2011_LOGIN": ".eed42af991497579faf8",
  "U2012_LOGINHELP": ".fd05b6bdf0b9297801e8",
  "U2014_SECURITY": ".b72caf39a3c973c401a2",
  "U2086_EFTPENDINGPAYMENTS": ".1e0b36a2b373c80a51ac",
  "U2087_EFTPAYMENTSNOTIFICATIONS": ".147cad4d4db1c0c2ed29",
  "U2110_ACCOUNTCREATION": ".d72380573b1342de6a37",
  "U2121_INDIVIDUALPROFILE": ".e97b7a6e5b3aba470f8d",
  "U2131_ORGANIZATIONPROFILE": ".1c59b2aa0692d444579f",
  "U2112_ERPBINDING": ".39d7fcaa9a0adc7ceeb3",
  "U2113_SUPPLIERINVITATION": ".f33a06b8863783f43fd5",
  "U2118_RBBINDING": ".f4538e97cf3fa8fefd72",
  "U2132_AFFILIATION": ".bcd8566221f71c968c11",
  "U2133_BUSINESSLINKS": ".66162ee9d575a6e57344",
  "U2181": ".11f5934cc2f4e6c2c04d",
  "U2211_SUBSCRIPTIONOFFER": ".9778125d90742a19fe2a",
  "U2212_SUBSCRIPTIONFILTER": ".bff1d20566067356cd12",
  "U2213_SUBSCRIPTION": ".5145f730206d4c42fa72",
  "U4111_CREDITCARDADDITION": ".e68db7d872bb2416aee5",
  "U4112_CREDITCARDDETAIL": ".c91b8229d9589e0d6f4b",
  "U4121_BANKACCOUNTADDITION": ".af7a4ad4686845822359",
  "U4122_BANKACCOUNTDETAIL": ".e1f72000016c04e47188",
  "U4303_ANONYMOUSTRANSACTION": ".b548991bd8118f9c5260",
  "U4401_PAYMENTGATEWAY": ".efe1322f752166b1f60b",
  "U4481_PROCESSINVOICEINSTALMENTPAYMENTS": ".215356fd6afd30c156d9",
  "U5000_RECEIVABLE": ".884dbe76b7f2c12040c7",
  "U5101_STATEMENTOFACCOUNT": ".ca0cd6e5e24d4f67cf39",
  "U5102_INVOICE": ".3ce6f46ddd1bd10bbff9",
  "U5103_PADAGREEMENT": ".c875a8c872712c19302d",
  "U5121_SENTINVOICE": ".4e2b19c178b81d7fa754",
  "U5124_PGRECEIVEDPAYMENT": ".90bc155f2ab740221c61",
  "U5120_INVOICES": ".6837531d54160d80b88f",
  "U5123_PADAGREEMENTCONSOLE": ".10746608f77ef27d7ca2",
  "U5132_PAYMENTRECONCILIATION": ".1e1fcef7a8533755977a",
  "U5133_BANKRECONCILIATION": ".a1e29175dd186c1afe95",
  "U6112_BUSINESSLINK": ".4eda4094228d19e0bd3d",
  "U6113_SUPPLIERRECORD": ".59ada109579e66a978a9",
  "U6211_PAYLOT": ".14519f71463227d45795",
  "U6311_PURCHASE": ".274d4a4532c4748bcd8b",
  "U2115_AFFILIATIONINVITATION": ".73e20129b774a7afb9e1",
  "U6100_PAYABLE": ".77f2bd4adfcc722ec94b",
  "U6131_SENTPAYMENT": ".017ba4617bf37171d988",
  "U6191_SENTPAYMENTREPORT": ".aa09fd087d65a2cf5f68",
  "U6221_SENTPAYMENT": ".d21080157eba09176afd",
  "U6422_INTERACSENTPAYMENT": ".f7a835c77f6dd27831ee",
  "U6531_TRANSACTIONPAYMENT": ".2ef2fbd8f3e77991d2fe",
  "U5131_RECEIVEDPAYMENT": ".d83a3d5a50cbfe449a50",
  "U5191_RECEIVEDPAYMENTREPORT": ".fc26869a46a2e71b8ed1",
  "U2001_HOME": ".aec9b7df53469a703232",
  "U2024_REPORTLOG": ".1bafddf79e227bf213f2",
  "U2111_INVOICEPORTALBINDING": ".85d3aa1962b4a96d6309",
  "U2116_ANONYMOUSSUPPLIERINVITATION": ".dc470a12fd1a66de8069",
  "U6111_SUPPLIERINVITATIONCONSOLE": ".d23b41607ccda1b2f515",
  "U6511_TRANSACTIONPARTY": ".f39955ba52ed93360566",
  "U6212_PAYROLLPARTY": ".f31859692e3db1f62765",
  "U6121_EFTLOT": ".ea878c2efedee6dfd37d",
  "U6412_INTERACFILE": ".806291ab62c3c36c213e",
  "U6122_EFTAPPROVAL": ".d79d04937328190a793b",
  "U6512_TRANSACTIONLOT": ".7d3681bf287dad322cf1",
  "U6123_EFTAPPROVAL": ".c82f178eee9355fa3a5d",
  "U6123_EFTAPPROVALMOBILE": ".aa48b28dd829c0faef7d",
  "U2012_LOGIN": ".7eddcf0899765fdc8407",
  "U2000_PAYMENT": ".9212f5914fc1bc548df7",
  "U4101_CREDITCARDBANKACCOUNTSUMMARY": ".58203f5887a89b2caf1f",
  "U2000_PAYMENTMODEUPDATE": ".0f9531b80f16883e334d",
  "U6114_TELUSBILLER": ".90e70d7ff7347d1fa45e",
  "U4403_CREDITCARDTERMINAL": ".ea8dce5fd832bcb1b049",
  "U4404_INTERACTERMINAL": ".d6228ddac1b70dc6a222",
  "U2000_SAMPLECHEQUESELECTION": ".7d5dd78d2c98eb58d1d9",
  "U2023_TUTORIALVIDEO": ".4743f7be29245d3932f2",
  "U2134_ORGANIZATIONBILLING": ".db27a8fc1443c92d1cb2",
  "U6414_BANKACCOUNTWALLET": ".4e7b97e7f48c6e7f7a9a",
  "U2022_INSTANTACCOUNTVERIFICATION": ".d7c41fbe039194f1b6be",
  "U6532_PENDINGTRANSACTION": ".6a26431af31a59212250",
  "U4301_TRANSACTIONSSUMMARY": ".77369717775d693122aa",
  "U5111_SENTINVOICESTRACKING": ".0946a4a633f49edd924c",
  "U2026_NETPROMOTERSCORE": ".7da5f0602bfa9e48e44d",
  "U2000_ACCOUNTPERSONNALINFORMATIONS": ".b42d57b6669842c2f41b",
  "U2000_INTRO": ".850f4a85b3a962fb4eb1",
  "U2000_ELECTRONICSIGNATURE": ".fd09d590648eab57a048",
  "U2000_VERIFYEMAIL": ".17e2dbc1c9d6aae7e5e0",
  "U2200_SUBSCRIPTION": ".3c6a0a9ae517ccb7aabd",
  "U2000_VERIFYIDENTITY": ".4ff028bb7c96a474d580",
  "U2000_ATTACHMENTVIEWER": ".1aca2302adb9b6788bef",
  "U2212_PLANLIST": ".56617fa5855c1fd78e09",
  "U3002_TAXBILLSEARCHRESULT": ".2dfc8dab7514be9eb75c"
}