import { Injectable } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { RoutingService } from '../../../gamma/routing';
import { SecurityParams, SecurityService } from '../../../gamma/security';
import { ISubNavbarParamsOption } from '../../../gamma/subnavbar/subnavbar.interface';
import { U2000LS_ModuleDefaultRoute } from '../U2000_localstorages';
import {
    U2000ST_ActiveInvoiceRecipientTask,
    U2000ST_ManageBankingReconciliationTask,
    U2000ST_ManagePadAgreementAsClientTask,
    U2000ST_ManagePadAgreementInvitationsTask,
    U2000ST_ManageSuppliersTask,
    U2000ST_PayablesElectronicChequesSubscriberTask,
    U2000ST_ReceivablesElectronicInvoicesSubscriberTask,
    U2000ST_ReconciliatePaymentsTask,
    U2000ST_SearchAndViewMyPurchasesTask,
    U2000ST_SearchEFTFileTransactionsForApprovalTask,
    U2000ST_SearchMessagesSubmittedByMeViaThePlatformTask,
    U2000ST_SearchMyEBusinessLinkInvitationsTask,
    U2000ST_SearchMyElectonicFundsTransferPaymentTransactionTask,
    U2000ST_SearchMyElectronicTransactionFilesTask,
    U2000ST_SubscriberToPaymentTask,
    U2000ST_ViewMyApplicationChannelAsClientTask,
    U2000ST_ViewMyReceivedPaymentsTask,
    U2000ST_ViewPaymentGatewayPaymentTransactionTask,
    U2000ST_ViewPaymentLotsPmtProcessorTask,
    U2000ST_ViewReceivedPaymentsReportTask,
    U2000ST_ViewSentPaymentsPmtProcessorTask,
    U2000ST_ViewSentPaymentsReportTask,
    U2000ST_ViewThePayerPaymentGatewayTask,
} from '../U2000_securitytags';
import { U2000_AuthenticationService } from './U2000_authentication.service';

export interface IU2000_Application {
    id: string;
    label: string;
    iconClass?: string;
    icon?: IconProp;
    module: string;
    route?: string[];
    routeFn?: (application: IU2000_Application) => string[];
    securityTags?: string | string[] | SecurityParams;
    securityFn?: (application: IU2000_Application) => boolean;
    options?: ISubNavbarParamsOption[];
}

@Injectable()
export class U2000_ApplicationMenuService {
    applications: IU2000_Application[];

    u2100_AccountOptions: ISubNavbarParamsOption[];
    u4000_WalletOptions: ISubNavbarParamsOption[];
    u5100_InvoicePortalOptions: ISubNavbarParamsOption[];
    u5000_PaymentGatewayOptions: ISubNavbarParamsOption[];
    u5000_ReceivableOptions: ISubNavbarParamsOption[];
    u6100_PayableOptions: ISubNavbarParamsOption[];
    u6200_PayrollOptions: ISubNavbarParamsOption[];
    u6300_PaymentGatewayOptions: ISubNavbarParamsOption[];
    u6400_PaymentProcessorOptions: ISubNavbarParamsOption[];
    u6500_TransactionOptions: ISubNavbarParamsOption[];

    constructor(private securityService: SecurityService, private routingService: RoutingService, private authService: U2000_AuthenticationService) {
        this.logicalUnitOptions();

        this.applications = [
            {
                id: 'wallet',
                label: 'lblWallet',
                icon: ['far', 'credit-card'],
                module: 'U4',
                routeFn: app => this.firstOptionRoute(app.options),
                options: this.u4000_WalletOptions,
            },
            {
                id: 'invoice-portal',
                label: 'lblInvoicePortal',
                icon: ['fas', 'desktop'],
                module: 'U510',
                routeFn: app => this.firstOptionRoute(app.options),
                securityFn: app => this.hasAccessToInvoicePortal(app),
                options: this.u5100_InvoicePortalOptions,
            },
            {
                id: 'electronic-invoices',
                label: 'lblElectronicInvoices',
                icon: ['fas', 'file-invoice-dollar'],
                module: 'U5',
                routeFn: app => this.firstOptionRoute(app.options),
                securityFn: app => this.hasAccessToElectronicInvoice(app),
                options: this.u5000_ReceivableOptions,
            },
            {
                id: 'payment-gateway',
                label: 'lblPaymentGateway', // Supplier
                icon: ['fas', 'cash-register'],
                module: 'U5',
                routeFn: app => this.firstOptionRoute(app.options),
                securityFn: app => this.hasAccessToPaymentGateway(app),
                options: this.u5000_PaymentGatewayOptions,
            },
            {
                id: 'electronic-cheques',
                label: 'lblElectronicCheques',
                icon: ['fas', 'money-check-alt'],
                module: 'U61',
                routeFn: app => this.firstOptionRoute(app.options),
                securityFn: app => this.hasAccessToElectronicCheques(app),
                options: this.u6100_PayableOptions,
            },
            {
                id: 'payroll',
                label: 'lblPayroll',
                icon: ['fas', 'hand-holding-usd'],
                module: 'U62',
                routeFn: app => this.firstOptionRoute(app.options),
                securityFn: app => this.hasAccessToPayroll(app),
                options: this.u6200_PayrollOptions,
            },
            {
                id: 'payment-gateway',
                label: 'lblPaymentGateway', // Payer
                icon: ['fas', 'cash-register'],
                module: 'U63',
                routeFn: app => this.firstOptionRoute(app.options),
                securityFn: app => this.hasAccessToPayerPaymentGatway(app),
                securityTags: [U2000ST_ViewThePayerPaymentGatewayTask],
                options: this.u6300_PaymentGatewayOptions,
            },
            {
                id: 'payment-processor',
                label: 'lblInterac',
                icon: ['fas', 'money-check'] as IconProp,
                module: 'U64',
                routeFn: app => this.firstOptionRoute(app.options),
                securityFn: app => this.hasAtLeastOneOptions(app.options),
                options: this.u6400_PaymentProcessorOptions,
            },
            {
                id: 'transactions',
                label: 'lblTransactions',
                icon: ['fas', 'cash-register'],
                module: 'U65',
                routeFn: app => this.firstOptionRoute(app.options),
                securityFn: app => this.hasAccessToTransactions(app),
                options: this.u6500_TransactionOptions,
            },
        ];
    }

    haveAccess(application: IU2000_Application) {
        if (application.securityFn) {
            return application.securityFn(application);
        } else if (application.securityTags != null) {
            return this.securityService.hasAccess(application.securityTags);
        }
        return true;
    }

    firstOptionRoute(options: ISubNavbarParamsOption[]): string[] {
        for (let i = 0; i < options.length; i++) {
            if (options[i].options == null) {
                if (options[i].securityFn) {
                    if (options[i].securityFn()) {
                        return options[i].route;
                    }
                } else if (options[i].securityTags != null) {
                    if (this.securityService.hasAccess(options[i].securityTags)) {
                        return options[i].route;
                    }
                } else {
                    return options[i].route;
                }
            } else {
                const route = this.firstOptionRoute(options[i].options);
                if (route != null) {
                    return route;
                }
            }
        }
        return null;
    }

    hasAtLeastOneOptions(options: ISubNavbarParamsOption[]): boolean {
        for (let i = 0; i < options.length; i++) {
            if (options[i].options == null) {
                if (options[i].securityFn) {
                    if (options[i].securityFn()) {
                        return true;
                    }
                } else if (options[i].securityTags != null) {
                    if (this.securityService.hasAccess(options[i].securityTags)) {
                        return true;
                    }
                }
            } else {
                if (this.hasAtLeastOneOptions(options[i].options)) {
                    return true;
                }
            }
        }
        return false;
    }

    hasAccessToInvoicePortal(application: IU2000_Application): boolean {
        return this.hasAtLeastOneOptions(application.options);
    }

    hasAccessToPayerPaymentGatway(application: IU2000_Application): boolean {
        if (this.securityService.hasAccess(U2000ST_ViewThePayerPaymentGatewayTask)) {
            return this.hasAtLeastOneOptions(application.options);
        }
        return false;
    }

    hasAccessToElectronicInvoice(application: IU2000_Application): boolean {
        if (this.securityService.hasAccess(U2000ST_ReceivablesElectronicInvoicesSubscriberTask)) {
            return this.hasAtLeastOneOptions(application.options);
        }
        return false;
    }

    hasAccessToPaymentGateway(application: IU2000_Application): boolean {
        if (this.securityService.hasAccess(U2000ST_SubscriberToPaymentTask)) {
            return this.hasAtLeastOneOptions(application.options);
        }
        return false;
    }

    hasAccessToElectronicCheques(application: IU2000_Application): boolean {
        if (this.securityService.hasAccess(U2000ST_PayablesElectronicChequesSubscriberTask)) {
            return this.hasAtLeastOneOptions(application.options);
        }
        return false;
    }

    hasAccessToPayroll(application: IU2000_Application): boolean {
        return this.hasAtLeastOneOptions(application.options);
    }

    hasAccessToTransactions(application: IU2000_Application): boolean {
        return this.hasAtLeastOneOptions(application.options);
    }

    haveAccessToRoute(route: string | string[], options: ISubNavbarParamsOption[]) {
        if (options != null) {
            for (let i = 0; i < options.length; i++) {
                const option = options[i];
                if (option.options != null && option.options.length > 0) {
                    if (this.haveAccessToRoute(route, option.options)) {
                        return true;
                    }
                }
                if (option.route != null) {
                    if (JSON.stringify(option.route) === JSON.stringify(route)) {
                        if (option.securityTags == null || this.securityService.hasAccess(option.securityTags)) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    partOfModule(module: string) {
        return this.routingService.partOfModule(module);
    }

    getLocalStorageName(module: string) {
        if (this.authService.isLoggedIn) {
            return module + '_' + this.authService.agentInfo.id + '_' + this.authService.tenantId + '_' + U2000LS_ModuleDefaultRoute;
        } else {
            return module + '_' + U2000LS_ModuleDefaultRoute;
        }
    }

    logicalUnitOptions() {
        this.u2100_AccountOptions = [
            {
                label: 'lblMyAccount',
                route: ['/U2121'],
                activationCondition: ['U2121'],
            },
            {
                label: 'lblMyOrganization',
                route: ['/U2131'],
                activationCondition: ['U2131'],
                securityTags: ['SPAI-29'],
            },
            {
                label: 'lblUsers',
                route: ['/U2132'],
                activationCondition: ['U2132'],
                securityTags: ['SPAI-209'],
            },
            {
                label: 'lblBusinessLinks',
                route: ['/U2133'],
                activationCondition: ['U2133'],
                securityTags: ['SPAI-162', 'SPAI-171'],
            },
            {
                label: 'lblMyBilling',
                route: ['/U2134'],
                activationCondition: ['U2134'],
                securityTags: ['SPAI-362'],
            },
        ];

        this.u4000_WalletOptions = [
            {
                label: 'lblCardsAndAccounts',
                route: ['/U4101'],
                activationCondition: ['U4101'],
            },
            {
                label: 'lblTransactions',
                route: ['/U4302'],
                activationCondition: ['U4302'],
            },
        ];

        this.u5100_InvoicePortalOptions = [
            {
                label: 'lblInvoicePortal',
                route: ['/U5101'],
                activationCondition: ['U5101', 'U5102'],
                securityTags: U2000ST_ActiveInvoiceRecipientTask,
            },
            {
                label: 'lblAgreements',
                route: ['/U5103'],
                activationCondition: ['U5103'],
                securityTags: U2000ST_ManagePadAgreementAsClientTask,
            },
        ];

        this.u5000_PaymentGatewayOptions = [
            {
                label: 'lblPaymentGateway',
                route: ['/U5124'],
                activationCondition: ['U5124'],
                securityTags: [U2000ST_ViewPaymentGatewayPaymentTransactionTask],
            },
            {
                label: 'lblPayments',
                route: ['/U5131_PG'],
                activationCondition: ['U5131'],
                securityTags: [U2000ST_ViewMyReceivedPaymentsTask],
            },
            {
                label: 'lblReconciliation',
                options: [
                    {
                        label: 'lblPayments',
                        route: ['/U5132'],
                        activationCondition: ['U5132'],
                        securityTags: [U2000ST_ReconciliatePaymentsTask],
                    },
                    {
                        label: 'lblBanking',
                        route: ['/U5133'],
                        activationCondition: ['U5133'],
                        securityTags: [U2000ST_ManageBankingReconciliationTask],
                    },
                ],
            },
            {
                label: 'lblReports',
                options: [
                    {
                        label: 'lblReceivedPayments',
                        route: ['/U5191_PG'],
                        activationCondition: ['U5191'],
                        securityTags: U2000ST_ViewReceivedPaymentsReportTask,
                    },
                ],
            },
        ];

        this.u5000_ReceivableOptions = [
            {
                label: 'lblSentInvoice',
                route: ['/U5121'],
                activationCondition: ['U5121'],
                securityTags: [U2000ST_ReceivablesElectronicInvoicesSubscriberTask, U2000ST_SearchMessagesSubmittedByMeViaThePlatformTask],
            },
            {
                label: 'lblAgreements',
                route: ['/U5123'],
                activationCondition: ['U5123'],
                securityTags: [U2000ST_ManagePadAgreementInvitationsTask],
            },
            {
                label: 'lblReceivedPayments',
                route: ['/U5131'],
                activationCondition: ['U5131'],
                securityTags: [U2000ST_ViewMyReceivedPaymentsTask],
            },
            {
                label: 'lblInvoiceTracking',
                route: ['/U5111'],
                activationCondition: ['U5111'],
                securityTags: 'DEPRECATED',
            },
            {
                label: 'lblReports',
                options: [
                    {
                        label: 'lblReceivedPayments',
                        route: ['/U5191'],
                        activationCondition: ['U5191'],
                        securityTags: U2000ST_ViewReceivedPaymentsReportTask,
                    },
                ],
            },
        ];

        this.u6100_PayableOptions = [
            {
                label: 'lblPayments',
                options: [
                    {
                        label: 'lblEftLots',
                        route: ['/U6121'],
                        activationCondition: ['U6121'],
                        securityTags: U2000ST_SearchMyElectronicTransactionFilesTask,
                    },
                    {
                        label: 'lblApprovals',
                        route: ['/U6122'],
                        activationCondition: ['U6122'],
                        securityTags: U2000ST_SearchEFTFileTransactionsForApprovalTask,
                    },
                    {
                        label: 'lblSentPayments',
                        route: ['/U6131'],
                        activationCondition: ['U6131'],
                        securityTags: U2000ST_SearchMyElectonicFundsTransferPaymentTransactionTask,
                    },
                ],
            },
            {
                label: 'lblSuppliers',
                options: [
                    {
                        label: 'lblSuppliers',
                        route: ['/U6113'],
                        activationCondition: ['U6113'],
                        securityTags: U2000ST_ManageSuppliersTask,
                    },
                    {
                        label: 'lblInvitations',
                        route: ['/U6111'],
                        activationCondition: ['U6111'],
                        securityTags: U2000ST_SearchMyEBusinessLinkInvitationsTask,
                    },
                    {
                        label: 'lblBusinessLinks',
                        route: ['/U6112'],
                        activationCondition: ['U6112'],
                        securityTags: U2000ST_ViewMyApplicationChannelAsClientTask,
                    },
                ],
            },
            {
                label: 'lblReports',
                options: [
                    {
                        label: 'lblSentPayments',
                        route: ['/U6191'],
                        activationCondition: ['U6191'],
                        securityTags: U2000ST_ViewSentPaymentsReportTask,
                    },
                ],
            },
        ];

        this.u6200_PayrollOptions = [
            {
                label: 'lblPayLots',
                route: ['/U6211'],
                activationCondition: ['U6211'],
                securityTags: [],
            },
            {
                label: 'lblEmployees',
                route: ['/U6212'],
                activationCondition: ['U6212'],
                securityTags: [],
            },
            {
                label: 'lblSentPayments',
                route: ['/U6221'],
                activationCondition: ['U6221'],
                securityTags: [],
            },
        ];

        this.u6300_PaymentGatewayOptions = [
            {
                label: 'lblPurchases',
                route: ['/U6311'],
                activationCondition: ['U6311'],
                securityTags: [U2000ST_SearchAndViewMyPurchasesTask],
            },
        ];

        this.u6400_PaymentProcessorOptions = [
            {
                label: 'lblPayments',
                options: [
                    {
                        label: 'lblInteracLots',
                        route: ['/U6412'],
                        activationCondition: ['U6412'],
                        securityTags: U2000ST_ViewPaymentLotsPmtProcessorTask,
                    },
                    {
                        label: 'lblSentPayments',
                        route: ['/U6422'],
                        activationCondition: ['U6422'],
                        securityTags: U2000ST_ViewSentPaymentsPmtProcessorTask,
                    },
                    {
                        label: 'lblBankAccountWallet',
                        route: ['/U6414'],
                        activationCondition: ['U6414'],
                        securityTags: U2000ST_ViewPaymentLotsPmtProcessorTask,
                    },
                ],
            },
        ];

        this.u6500_TransactionOptions = [
            {
                label: 'lblClients',
                route: ['/U6511'],
                activationCondition: ['U6511'],
                securityTags: ['SPAI-412'],
            },
            {
                label: 'lblTransactionsLots',
                route: ['/U6512'],
                activationCondition: ['U6512'],
                securityTags: ['SPPR-410'],
            },
            {
                label: 'lblPayments',
                options: [
                    {
                        label: 'lblProcessed',
                        route: ['/U6531'],
                        activationCondition: ['U6531'],
                        securityTags: ['SPPR-410'],
                    },
                    {
                        label: 'lblUpcoming',
                        route: ['/U6532'],
                        activationCondition: ['U6532'],
                        securityTags: ['SPPR-410'],
                    },
                ],
            },
        ];
    }
}
